import browserUpdate from 'browser-update/update.npm';

// See : https://github.com/browser-update/browser-update/wiki/Details-on-configuration
browserUpdate({
  required: {
    e: 89, // Edge
    i: 12, // IE
    f: 87, // Firefox
    s: 13, // Safari
    c: 89, // Chrome
  },
  insecure: true,
});
